// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./o1e0e7AZb-0.js";
import ArrowDown from "./ZF1ah4dh9";
const ArrowDownFonts = getFonts(ArrowDown);

const cycleOrder = ["tn9duUx26", "zu8u35aFL"];

const serializationHash = "framer-l9wSQ"

const variantClassNames = {tn9duUx26: "framer-v-1bl86pf", zu8u35aFL: "framer-v-8imiv8"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {qMmWsyKjJ: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "tn9duUx26", "Variant 2": "zu8u35aFL"}

const getProps = ({height, hover, id, title, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "tn9duUx26", XNd5Ahchr: title ?? props.XNd5Ahchr ?? "Learn From Me", z6RCTJskf: hover ?? props.z6RCTJskf} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;hover?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XNd5Ahchr, z6RCTJskf, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "tn9duUx26", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1yxtzbl = activeVariantCallback(async (...args) => {
if (z6RCTJskf) {
const res = await z6RCTJskf(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1bl86pf", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"tn9duUx26"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter1yxtzbl} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{opacity: 1, ...style}} variants={{zu8u35aFL: {opacity: 0.5}}} {...addPropertyOverrides({zu8u35aFL: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7Qml0dGVyLXJlZ3VsYXI=", "--framer-font-family": "\"Bitter\", \"Bitter Placeholder\", serif", "--framer-letter-spacing": "0.02em", "--framer-line-height": "1.3em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-5900d408-4703-403c-84e4-19f57a96b1e6, rgb(153, 153, 153)))"}}>Learn From Me</motion.p></React.Fragment>} className={"framer-xxbz19"} fonts={["GF;Bitter-regular"]} layoutDependency={layoutDependency} layoutId={"Wl3F34CUV"} style={{"--extracted-r6o4lv": "var(--token-5900d408-4703-403c-84e4-19f57a96b1e6, rgb(153, 153, 153))"}} text={XNd5Ahchr} verticalAlignment={"top"} withExternalLayout/><ComponentViewportProvider ><motion.div className={"framer-s5gsew-container"} layoutDependency={layoutDependency} layoutId={"C7iIvw3TS-container"}><ArrowDown height={"100%"} id={"C7iIvw3TS"} layoutId={"C7iIvw3TS"} variant={"BBHYxfDgL"} width={"100%"} {...addPropertyOverrides({zu8u35aFL: {variant: "EudZfsZZt"}}, baseVariant, gestureVariant)}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-l9wSQ.framer-1hqh7mq, .framer-l9wSQ .framer-1hqh7mq { display: block; }", ".framer-l9wSQ.framer-1bl86pf { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: min-content; }", ".framer-l9wSQ .framer-xxbz19 { -webkit-user-select: none; flex: none; height: auto; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-l9wSQ .framer-s5gsew-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-l9wSQ.framer-1bl86pf { gap: 0px; } .framer-l9wSQ.framer-1bl86pf > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-l9wSQ.framer-1bl86pf > :first-child { margin-left: 0px; } .framer-l9wSQ.framer-1bl86pf > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 120
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"zu8u35aFL":{"layout":["auto","auto"]}}}
 * @framerVariables {"XNd5Ahchr":"title","z6RCTJskf":"hover"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framero1e0e7AZb: React.ComponentType<Props> = withCSS(Component, css, "framer-l9wSQ") as typeof Component;
export default Framero1e0e7AZb;

Framero1e0e7AZb.displayName = "Learn From Me";

Framero1e0e7AZb.defaultProps = {height: 20, width: 120};

addPropertyControls(Framero1e0e7AZb, {variant: {options: ["tn9duUx26", "zu8u35aFL"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, XNd5Ahchr: {defaultValue: "Learn From Me", displayTextArea: false, title: "Title", type: ControlType.String}, z6RCTJskf: {title: "Hover", type: ControlType.EventHandler}} as any)

addFonts(Framero1e0e7AZb, [{explicitInter: true, fonts: [{family: "Bitter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/bitter/v33/raxhHiqOu8IVPmnRc6SY1KXhnF_Y8fbfCLrEXFh2reU.woff2", weight: "400"}]}, ...ArrowDownFonts], {supportsExplicitInterCodegen: true})